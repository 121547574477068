import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '&:before': {
            content: '""',
            background: theme.palette.divider,
            width: 'auto',
            height: 1,
            marginRight: '2em',
            flexGrow: 1
        },
        
        '&:after': {
            content: '""',
            background: theme.palette.divider,
            width: 'auto',
            height: 1,
            marginLeft: '2em',
            flexGrow: 1        
        }
    },    
    content:{
        textAlign: 'center',
        flexGrow: 0
    },   
})

const HeaderWithDividers = ({ children, classes, ...props }) => {
    return (
        <Box className={classes.root} {...props}>
            <Typography variant="h2" color="textSecondary" className={classes.content}>
                {children}
            </Typography>
        </Box>
    )
}

export default withStyles(styles)(HeaderWithDividers)
