import React from 'react'
import { Box, Dialog, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import CompanyButton from './CompanyButton'
import HeaderWithDividers from './HeaderWithDividers'
import CompanyInput from './CompanyInput'
import CloseIcon from './CloseIcon'
import { useNavigate } from "@reach/router"

const ContactForm = () => {
    return (
        <form name='prmcf' action="/confirm" method="POST" netlify-honeypot="bot-field" data-netlify="true">
            <input type="hidden" name="form-name" value="prmcf" />

            <Box m={3} position="relative">
                <HeaderWithDividers>
                    Tell us about your project
                </HeaderWithDividers>

                <Box mt={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h3" as="h3">
                                Contact information
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <CompanyInput name={'name'} label="Your name" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <CompanyInput name={'company'} label="Your company" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <CompanyInput name={'email'} label="Your email" />
                        </Grid>
                    </Grid>
                </Box>

                <Box mt={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h3" as="h3">
                                About project
                                </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <CompanyInput name={'project'} label="Project name" />
                        </Grid>

                        <Grid item xs={12}>
                            <CompanyInput name={'description'} multiline rows={4} label="Project description" />
                        </Grid>

                        <Grid item container xs={12} justify="flex-end">
                            <CompanyButton type={'submit'}>Send Inquiry</CompanyButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </form>
    )
}

export default ContactForm
