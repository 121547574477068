import React from 'react';
import Layout from 'src/components/Layout';
import { Container, Typography, Box, Grid } from '@material-ui/core';
import CompanyButton from 'src/components/CompanyButton';
import { Link, useNavigate } from "@reach/router"
import ContactForm from 'src/components/ContactForm';


export default () => {

  return (
    <Layout>
      <Box mt={6} mb={10}>
        <Container maxWidth="md">
          <ContactForm />
        </Container>
      </Box>
    </Layout>
  );
}
