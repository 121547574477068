import React from 'react'
import { TextField } from '@material-ui/core'

const CompanyInput = props => {
    return <TextField
        variant="outlined"
        fullWidth
        {...props}
    />
}

export default CompanyInput
